import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Spin,
  Typography,
  Upload,
} from 'antd'
import {
  getApplicationTags,
  getOptions,
  REACT_APP_CLINICAL_URL,
  validateOrder,
} from '../../services/StatusDbApi'
import { UserContext } from '../../services/contexts/userContext'
import { DeleteTwoTone, FileAddTwoTone } from '@ant-design/icons'
import { isNil } from 'ramda'
import { ErrorNotification } from '../../services/helpers/helpers'
import {
  allowedDeliveryOptions,
  isStandaloneSampleForm,
  orderTypes,
  supportSystemURL,
} from '../../services/helpers/constants'
import { ErrorPage } from '../Error/ErrorPage'
import {
  validateCaseOrder,
  validateStandaloneOrder,
  findUniqueItems,
  initIsTrustedCustomer,
  serverValidator,
  extractStandaloneValues,
  extractCaseValues,
} from './helpers'
import styles from './OrderForm.module.css'
import { StandaloneSampleFormList } from '../../components/Forms/StandaloneSampleFormList'
import { CaseFormList } from '../../components/Forms/CaseFormList'
import { OrderDetailsModal } from 'components/OrderDetailsModal/OrderDetailsModal'

export const OrderFormPage = () => {
  const [options, setOptions] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const [error, setError] = useState()
  const [validationError, setValidationError] = useState<any>({ hasError: false, messages: [] })
  const [selectedAnalysisType, setSelectedAnalysisType] = useState<string | null>(null)
  const [dataDelivery, setDataDelivery] = useState<string | null>(null)
  const [customer, setCustomer] = useState<any>()
  const [isTrusted, setIsTrusted] = useState<boolean>(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSkipReceptionControl, setIsSkipReceptionControl] = useState(false)
  const [orderData, setOrderData] = useState<any>()
  const [validatingOrder, setValidatingOrder] = useState<boolean>(false)
  const [applicationTags, setApplicationTags] = useState([])
  const userContext = useContext(UserContext)
  const { Dragger } = Upload
  const [form] = Form.useForm()
  const { Text } = Typography

  const orderFieldsValidation = useCallback(() => {
    const order = form.getFieldsValue()
    const hasCase = !!order.cases?.length
    const hasCaseSample = order?.cases?.every((caseItem) => !!caseItem?.samples?.length)
    const hasStandaloneSample = !!order.samples?.length
    if (
      selectedAnalysisType &&
      (isStandaloneSampleForm(selectedAnalysisType)
        ? hasStandaloneSample
        : hasCase && hasCaseSample)
    ) {
      const extractedOrderValues = isStandaloneSampleForm(selectedAnalysisType)
        ? extractStandaloneValues(order)
        : extractCaseValues(order)
      validateOrder(userContext, selectedAnalysisType, extractedOrderValues)
        .then((response) => {
          form.setFieldsValue(response)
          return form.validateFields()
        })
        .catch(() => {
          setIsLoading(false)
          setValidationError({ hasError: true })
        })
    }
  }, [userContext, form, selectedAnalysisType])

  useEffect(() => {
    if (selectedAnalysisType) {
      getApplicationTags(userContext, selectedAnalysisType).then(({ applications }) =>
        setApplicationTags(applications)
      )
    }
  }, [selectedAnalysisType, userContext])

  useEffect(() => {
    getOptions(userContext)
      .then((response) => {
        setOptions(response)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }, [userContext])

  const uploadProps = {
    action: `${REACT_APP_CLINICAL_URL}/orderform`,
    accept: '.xlsx,.json',
    headers: { Authorization: 'Bearer ' + userContext?.token },
    maxCount: 1,
    showUploadList: { showRemoveIcon: false },
    onChange: ({ file }) => {
      if (file.status === 'error') {
        ErrorNotification('Could not upload file', file?.response?.message)
      } else if (!isNil(file.response)) {
        setValidatingOrder(true)
        form.resetFields()
        setIsSkipReceptionControl(false)
        const projectType = file?.response?.project_type
        validateOrder(userContext, projectType, file.response)
          .then((response) => {
            setCustomer(response?.customer?.value)
            setIsTrusted(initIsTrustedCustomer(response?.customer?.value, options))
            setSelectedAnalysisType(projectType)
            form.setFieldsValue(response)
            setValidatingOrder(false)
            setDataDelivery(response?.delivery_type?.value)
          })
          .catch((error) => {
            setError(error)
            setValidatingOrder(false)
          })
      }
    },
  }

  return (
    <div>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 5 }} />
      ) : error ? (
        <ErrorPage error={error} />
      ) : (
        <>
          <Card>
            <Spin tip={'Submitting order...'} spinning={isSubmittingOrder}>
              <div style={{ paddingBottom: 10 }}>
                <Dragger {...uploadProps} data-testid={'uploader'}>
                  <FileAddTwoTone style={{ fontSize: 16 }} /> Click or drag file to this area to
                  upload
                </Dragger>
                <Text type="secondary" style={{ fontSize: 10 }}>
                  Accepts{' '}
                  <a
                    href={`${supportSystemURL}solutions/articles/202000084019-documents-needed-for-orders`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    orderform
                  </a>{' '}
                  Excel (.xlsx) and JSON (.json) files for Balsamic, MIP and RML
                </Text>
              </div>
              <OrderDetailsModal
                orderData={orderData}
                isConfirmationModalOpen={isConfirmationModalOpen}
                isSubmittingOrder={isSubmittingOrder}
                setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                setIsSubmittingOrder={setIsSubmittingOrder}
                selectedAnalysisType={selectedAnalysisType}
                form={form}
              />
              <Form
                title={'New order'}
                validateMessages={{ required: 'required' }}
                colon={false}
                onFinish={(formValue) => {
                  selectedAnalysisType && isStandaloneSampleForm(selectedAnalysisType)
                    ? validateStandaloneOrder(
                        formValue,
                        setValidationError,
                        setIsConfirmationModalOpen,
                        setOrderData
                      )
                    : validateCaseOrder(
                        formValue,
                        setValidationError,
                        setIsConfirmationModalOpen,
                        setOrderData
                      )
                }}
                onFinishFailed={({ errorFields }) => {
                  ErrorNotification(
                    'Validation Error: Click on any error listed in this notification to navigate directly to the problematic field.',
                    <>
                      {findUniqueItems(errorFields).map((error: any) =>
                        selectedAnalysisType && isStandaloneSampleForm(selectedAnalysisType) ? (
                          <a
                            href={`#sample ${error?.name[1]}`}
                            key={`#sample ${error?.name[1]}`}
                            onClick={(e) => {
                              e.preventDefault()
                              document
                                .getElementById(`sample ${error?.name[1]}`)
                                ?.scrollIntoView({ behavior: 'smooth' })
                            }}
                          >
                            <Text type="danger">
                              {form.getFieldValue(['samples', error?.name[1], 'name', 'value'])}
                            </Text>
                            <br />
                          </a>
                        ) : (
                          <a
                            href={`#case ${error?.name[1]}`}
                            key={`#case ${error?.name[1]}`}
                            onClick={(e) => {
                              e.preventDefault()
                              document
                                .getElementById(`case ${error?.name[1]}`)
                                ?.scrollIntoView({ behavior: 'smooth' })
                            }}
                          >
                            <Text type="danger">
                              {form.getFieldValue(['cases', error?.name[1], 'name', 'value'])}
                            </Text>
                            <br />
                          </a>
                        )
                      )}
                    </>
                  )
                }}
                size={'small'}
                form={form}
              >
                <Spin tip={'Validating upload...'} spinning={validatingOrder}>
                  <Row className={styles.formRow}>
                    <Form.Item
                      name={['customer', 'value']}
                      rules={[
                        {
                          required: true,
                          validator: () => serverValidator('customer', form, null, null),
                        },
                      ]}
                    >
                      <Select
                        onSelect={(selection, option) => {
                          setCustomer(selection)
                          setIsTrusted(option.isTrusted)
                          setIsSkipReceptionControl(false)
                          orderFieldsValidation()
                          form.setFieldsValue({
                            cases: [],
                            samples: [],
                            project_type: null,
                            delivery_type: null,
                          })
                          setSelectedAnalysisType(null)
                          setDataDelivery(null)
                          setValidationError({ hasError: false, messages: [] })
                        }}
                        style={{ width: 300 }}
                        showSearch
                        placeholder="Select a customer"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={options?.customers?.map(({ value, text, isTrusted }) => {
                          return {
                            value: value,
                            label: text,
                            isTrusted: isTrusted,
                          }
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['project_type', 'value']}
                      required
                      label="Type"
                      rules={[
                        {
                          required: true,
                          validator: () => serverValidator('project_type', form, null, null),
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setSelectedAnalysisType(e)
                          form.setFieldsValue({
                            cases: [],
                            samples: [],
                            delivery_type: null,
                          })
                          orderFieldsValidation()
                          setDataDelivery(null)
                          setValidationError({ hasError: false, messages: [] })
                        }}
                        style={{ width: 300 }}
                        showSearch
                        placeholder="Select an analysis type"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={orderTypes.map(({ text, orderType }) => ({
                          value: orderType,
                          label: text,
                        }))}
                      ></Select>
                    </Form.Item>
                    {selectedAnalysisType ? (
                      <Form.Item
                        label="Delivery type"
                        name={['delivery_type', 'value']}
                        rules={[
                          {
                            required: true,
                            validator: () => serverValidator('delivery_type', form, null, null),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: 215 }}
                          onChange={(e) => {
                            setDataDelivery(e)
                            orderFieldsValidation()
                          }}
                          placeholder="Select delivery option"
                        >
                          {allowedDeliveryOptions[selectedAnalysisType]?.map(({ value, text }) => (
                            <Select.Option key={value} value={value}>
                              {text}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                    {isTrusted && (
                      <Form.Item
                        label="Skip reception control"
                        valuePropName={'checked'}
                        name={['skip_reception_control', 'value']}
                        rules={[
                          {
                            validator: () =>
                              serverValidator('skip_reception_control', form, null, null),
                          },
                        ]}
                      >
                        <Checkbox
                          onChange={({ target }) => {
                            setIsSkipReceptionControl(target.checked)
                            orderFieldsValidation()
                          }}
                        />
                      </Form.Item>
                    )}
                  </Row>

                  <Row className={styles.formRow}>
                    <>
                      <Form.Item
                        label="Order name"
                        name={['name', 'value']}
                        rules={[
                          {
                            required: true,
                            validator: () => serverValidator('name', form, null, null),
                          },
                        ]}
                        style={{ width: 300 }}
                      >
                        <Input onBlur={() => orderFieldsValidation()} />
                      </Form.Item>
                      <Form.Item
                        label="Comment"
                        name={['comment', 'value']}
                        style={{ width: 794 }}
                        rules={[
                          {
                            validator: () => serverValidator('comment', form, null, null),
                          },
                        ]}
                      >
                        <Input onBlur={() => orderFieldsValidation()} />
                      </Form.Item>
                    </>
                  </Row>

                  <Row className={styles.formRow}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit order
                      </Button>
                    </Form.Item>
                    <Popconfirm
                      title={`Are you sure you want to reset the order`}
                      onConfirm={() => {
                        form.resetFields()
                        setIsSkipReceptionControl(false)
                        setCustomer(null)
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button icon={<DeleteTwoTone />}>Reset order</Button>
                    </Popconfirm>
                  </Row>
                </Spin>
                {customer && selectedAnalysisType && dataDelivery && (
                  <>
                    {isStandaloneSampleForm(selectedAnalysisType) ? (
                      <StandaloneSampleFormList
                        form={form}
                        selectedAnalysisType={selectedAnalysisType}
                        options={options}
                        validationError={validationError}
                        skipReceptionControl={isSkipReceptionControl}
                        validatingOrder={validatingOrder}
                        applicationTags={applicationTags}
                      />
                    ) : (
                      <CaseFormList
                        form={form}
                        selectedAnalysisType={selectedAnalysisType}
                        options={options}
                        customer={customer}
                        validationError={validationError}
                        skipReceptionControl={isSkipReceptionControl}
                        validatingOrder={validatingOrder}
                        applicationTags={applicationTags}
                      />
                    )}
                  </>
                )}
              </Form>
            </Spin>
          </Card>
        </>
      )}
    </div>
  )
}
