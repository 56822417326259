import React, { useEffect } from 'react'
import styles from './Header.module.css'
import Logo from '../../assets/logo.svg'
import { Link } from 'react-router-dom'
import { Layout, Menu, Button } from 'antd'
import { UserAvatar } from '../Avatar/Avatar'
import { isNil } from 'ramda'
import { ErrorNotification } from 'services/helpers/helpers'
import { GoogleLogin } from '@react-oauth/google'
import { getUser } from 'services/StatusDbApi'

export const Header = ({ pages, setIsAuthLoading, logout, setToken, setUser, user }) => {
  const { Header } = Layout

  const topMenuItems = [
    {
      key: '/',
      label: (
        <Link to={'/'}>
          <span>Home</span>
        </Link>
      ),
    },
    ...pages.map((page) => {
      return {
        key: `/${page?.fields?.title?.route}`,
        label: (
          <Link to={page?.fields?.title?.route}>
            <span>{page?.fields?.title?.title}</span>
          </Link>
        ),
      }
    }),
    {
      key: '/applications',
      label: (
        <Link to={'/applications'}>
          <span>Applications</span>
        </Link>
      ),
    },
    {
      key: '/contact-us',
      label: (
        <Link to={'/contact-us'}>
          <span>Contact Us</span>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    if (topMenuItems.some((item) => item.key === location?.pathname)) {
      setIsAuthLoading(false)
    } else {
      setTimeout(() => {
        setIsAuthLoading(false)
      }, 5500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, topMenuItems])

  return (
    <Header className={styles.headerContainer}>
      <Link to="/">
        <img className={styles.logo} src={Logo} alt="Small CG logo" />
      </Link>
      <Menu
        theme="light"
        mode="horizontal"
        selectedKeys={[location.pathname]}
        items={topMenuItems}
        className={styles.menu}
      />

      {isNil(user) ? (
        <div className={styles.loginBtn}>
          <GoogleLogin
            size="medium"
            onSuccess={(response) => {
              if ('credential' in response) {
                setToken(response.credential || null)
                getUser(response.credential)
                  .then(({ user }) => {
                    setUser(user)
                    setIsAuthLoading(false)
                  })
                  .catch((error) => {
                    if (error.response.status === 403) {
                      ErrorNotification(
                        'Access Denied',
                        'You do not have permission to login with this account.'
                      )
                    } else {
                      ErrorNotification(
                        'Could not login',
                        'Something went wrong. Please try again later.'
                      )
                    }
                  })
              }
            }}
            onError={() => console.log('Login Failed')}
            useOneTap
            auto_select
            use_fedcm_for_prompt
          />
        </div>
      ) : (
        <div className={styles.logoutBtnAvatar}>
          <Link to="/account">
            <UserAvatar user={user} />
          </Link>
          <Link to="/">
            <Button type="primary" onClick={logout}>
              Logout
            </Button>
          </Link>
        </div>
      )}
    </Header>
  )
}
